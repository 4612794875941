.able-to-work-consequences {
    .choice-container {
        &:not(:last-of-type) {
            border-bottom: 1px solid #F3F3F3;
            padding-bottom: 50px;
            margin-bottom: 68px;
        }
    }

    .choice-title {
        font-size: 20px;
        line-height: 26px;
    }

    button {
        margin-top: 24px;
    }
}