div.excel-button {

    .open-modal-button {
        position: relative; 
        background-color: #009FE3;
        border-radius: 4px;
        display: flex;
        align-items: stretch;
        padding: 0;

        outline: 0;
        border: 0;

        .content {
            position: relative; 
            display: flex;
            align-items: center;
            padding: 12px 15px;
            border-right: 1px solid #32B2E8;
    
            img {
                margin-right: 10px;
            }
    
            span {
                font-size: 13px;
                font-weight: 500;
                color: white;
            }
        }
    
        .arrow {
            position: relative; 
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px;
    
            &:before {
                display: block;
                content: '';
                width: 0; 
                height: 0; 
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;            
                border-top: 4px solid white;
            }
        }
    
        &:hover {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: white;
                opacity: 0.1;
            }
        }
    }
}