.desktop-dashboard-pages {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #F1F9FF;
    position: absolute;

    &.notifications {
        .desktop-dashboard-side-menu {
            width: 0;
            opacity: 0;
            pointer-events: none;
        }
    }

    .activate-notifications-actions {
        padding: 32px;

        button {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    .desktop-dashboard-side-menu {
        z-index: 1;
        flex-shrink: 0;
        width: var(--desktop-side-menu-width);        
        transition: width .3s, opacity .3s;
    }
    
    .desktop-dashboard-content {
        position: relative;
        height: 100%;
        flex-grow: 1;
    }

    .desktop-dashboard-page {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: 200ms opacity 200ms;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 400ms;
        }
    }
}

