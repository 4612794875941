@import "../../styles/general/sass-variables";

.controlled-ion-modal {

    --backdrop-opacity: 0.8;
    
    &.center {
        .modal-wrapper {
            border-radius: 4px;
        }

        .ion-page {
            padding: 40px;
        }
    }

    &.type-small {
        
        .modal-wrapper {
            background: transparent !important;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: none;
        }

        .ion-page {
            pointer-events: auto;            
            margin: 24px;
            --width: auto;
            --height: auto;
            position: static;
            contain: content;
        }
    }

    &.type-bottom {
        --width: 100% !important;
        --height: 100% !important;
        --min-width: auto;
        --max-width: auto;
    
        .modal-wrapper {
            background: transparent;
            pointer-events: none;
        }
    
        .ion-page {
            pointer-events: auto;
            right: auto;
            top: auto;
            width: 100%;
            contain: content;
            background: white;
            border-radius: 20px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    
        .buttons-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: env(safe-area-inset-bottom);
    
            &.grow {
                button, a {
                    flex-grow: 1;
                }
            }
    
            &:not(.grow) {
                button, a {
                    width: 50%;
                }
            }
    
            button, a {
                flex-grow: 1;
                letter-spacing: 0;                
    
                &:first-child {
                    margin-right: 8px;
                }
    
                &:last-child {
                    margin-left: 8px;
                }
            }
        }

        @media (max-width: $small-phone-width) {
            h2 {
                font-size: 16px;
            }

            p {
                font-size: 15px;
            }

            .buttons-container {
                button {
                    padding-left: 6px;
                    padding-right: 6px;
                    font-size: 14px;
                    height: 48px;
                }
            }
        }
    }
}