.desktop-new-report {
    position: relative;
    height: 100%;

    &.type-tso {
        .pages-container {
            .image-container .image-content {
                background-image: url('/assets/icon/tso.svg');
            }
        }
    }

    &.type-substitution {
        .pages-container {
            .image-container {
                padding-bottom: 30px;

                .image-content {
                    background-image: url('/assets/bg/substitution.svg');
                }
            }
        }
    }

    .page-container {
        padding-top: 60px;
    }

    &.is-success {
        .page-progress, .page-actions {
            pointer-events: none;
            opacity: 0;
        }
    }

    .tso-causes {
        .selectable-btn-icon {
            width: 88px;

            img {
                height: 40px;
            }
        }

        .selectable-btn-title {
            font-size: 14px;
        }
    }
}