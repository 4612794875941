@import "../../styles/general/sass-variables";

.login-greetings {
    text-align: center;

    h1 {
        color: var(--ion-color-primary);
    }

    img {
        display: block;
        margin: 0 auto 30px;
        width: 74px;
        height: 74px;
    }

    @media (max-height: $small-phone-height) {
        h1 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }

        img {
            width: 60px;
            height: 60px;
        }
    }
}