.scrollable-page {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .scrollable-page-content {
        width: 100%;
        overflow: auto;        
    }

    .scrollable-page-header,
    .scrollable-page-footer {
        flex-shrink: 0;
    }

    .scrollable-page-header {
        display: flex;
        align-items: center;
        height: 56px;

        button {
            display: block;
            height: 100%;
            background: transparent;
            padding-right: 0;
            outline: 0;

            ion-icon {
                display: block;
                font-size: 24px;
            }
        }

        .page-header-title {
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            padding: 0 24px;            
        }
    }

    .scrollable-page-footer {
        padding: 0 24px 24px;
    }
}