.push-notifications-settings {
    .updating-toggle {
        &.updating {
            pointer-events: none;
        }
    }

    &.permissions-refused {
        pointer-events: none;
        opacity: 0.5;
    }
}