h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

h1 {
    font-size: 24px;
    font-weight: bold;    
}

h2 {
    font-size: 20px;
    font-weight: 600;
}

h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 32px 0 16px;
}

p {
    line-height: 1.5em;
}

a {
    color: inherit;
    text-decoration: none;
}