.mobile-able-to-work {

    ion-content {
        --background: linear-gradient(180deg, var(--ion-color-primary) 55%, white 60%);
    }
    
    .able-to-work-tutorial {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tutorial-title {
            display: none;
        }

        .image-banner {
            flex-grow: 1;
        }
    }

    &.is-tutorial {   

    }

    .scroll-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .scrollable-white-content-inner {
        .transition-container,
        .transition-content {
            height: auto;
        }
    }
}