.mobile-single-news-details {

    ion-content {
        --background: #F1F9FF;
    }

    .fixed-content {
        background-image: url('/assets/bg/default-news.jpg');
    }

    .single-news-details {
        padding: 32px 24px 40px;

        .title {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 16px;
        }
    
        .date {
            font-size: 14px;
            line-height: 24px;
        }
    
        .subtitle-container {
            margin: 32px 0;
        }
    
        .subtitle-content {
            font-weight: 500;
            padding: 24px 0;
        }
    }
}