.simple-list-modal {
    width: 350px;

    .basic-modal-content {
        height: 408px;
        overflow: auto;
    }

    .list-items {

    }

    .list-item {
        margin-bottom: 1em;
    }
}