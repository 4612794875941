.tso-causes {

    .causes-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 32px;
        color: #4D4D4D;
    }

    .causes-caption {
        font-size: 14px;
        font-weight: 500;
        color: #949494;
    }

    .selectable-btn {
        text-transform: none;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 16px;
        cursor: pointer;

        &.selected {            
            background: #EAF7FD;

            .selectable-btn-content {
                color: var(--ion-color-primary);
                border-color: var(--ion-color-primary);
            }
        }
    }

    .selectable-btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 144px;
        flex-shrink: 0;
        background-color: var(--ion-color-primary);
        text-align: center;
        border-radius: 4px 0 0 4px;

        img {
            height: 56px;
        }
    }

    .selectable-btn-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        color: var(--ion-color-dark);        
        border: 2px solid #ECECEC;        
        border-radius: 4px;
        transition: background-color .2s, border-color .2s;
    }

    .selectable-btn-icon, .selectable-btn-content {
        padding: 20px;
    }

    .selectable-btn-title {
        font-weight: 600;
    }

    .selectable-btn-description {
        margin-top: 14px;
        font-size: 14px;
        line-height: 20px;
    }

    .causes-list {
        .selectable-btn-content {
            border-radius: 0 4px 4px 0;
            border-left: none;
        }
    }

    .cause-other {        
        margin: 0 0 16px;
        font-weight: 600;

        &.filled {
            .MuiOutlinedInput-notchedOutline {
                border-color: var(--ion-color-primary);
            }

            .MuiInputBase-root {
                background-color: #EAF7FD;
            }
        }

        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: var(--ion-color-primary);
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: 2px solid #ECECEC;
        }    
    }

    .none-container {
        .selectable-btn {
            margin-bottom: 0;
        }
    }
}