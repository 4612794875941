.transitionable-scrollable-white-content {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;

    .transition-container,
    .transition-content {
        height: 100%;
    }
    
    &.entered {
        .scrollable-white-content-inner {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }
    
    .scrollable-white-content-inner {
        position: relative;
        width: 100%;
        padding-top: 24px;
        flex-grow: 1;
        background-color: white;
        transition: border-radius .2s;
    }

    .transition-container {
        position: relative;
    }

    .transition-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .transition-content-page {
            flex-grow: 1;
            padding: 0 24px;
        }

        .transition-content-footer {
            border-top: 1px solid #f6f6f6;
            padding: 16px 24px;
            margin-top: 24px;
            margin-bottom: env(safe-area-inset-bottom);
        }

        &:first-of-type {
            position: relative;
        }

        &:not(:first-of-type) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: .3s opacity .3s;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity .3s;
        }

        .declaration-tutorial {
            position: relative;
            top: 50vh;
            transform: translateY(-100%);
            
            h1 {
                display: none;
            }
        }
    }
}