.opened-single-news {
    position: relative;
    width: 694px;

    .close-button-container {
        position: absolute;
        top: 48px;
    }

    .single-news-image {
        height: 0;
        padding-bottom: 54%;
        background-image: url('/assets/bg/default-news.jpg');
        background-color: #F0F0F0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .single-news-details {

        background-color: white;
        padding: 32px 40px 56px;

        .title {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 16px;
        }
    
        .date {
            font-size: 13px;
            line-height: 24px;
            font-weight: 600;
        }

        .subtitle-container {
            margin: 16px 0 32px;
        }

        .subtitle-content {            
            padding: 32px 0 24px;
        }

        .subtitle-separator.top {
            width: 100%;
        }
    }
}