.militant-actions {
    .fiq-button {
        height: 56px;
        font-size: 16px;
        font-weight: 600;

        &:last-child {
            margin-top: 10px;
            border-color: white;
            border-width: 2px;
            color: var(--ion-color-primary);
        }
    }
}