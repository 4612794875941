@import "../../styles/general/sass-variables";

.checkbox-buttons {

    display: flex;
    align-items: center;

    .checkbox-button {
        display: inline-flex;
        align-items: center;
        outline: 0;
        font-size: 14px;
        font-weight: 600;
        background: transparent;
    }

    &.theme-fiq {
        .checkbox-button {    
            position: relative;            
            border: 1px solid #DADADA;
            padding: 8px 16px;
            min-height: 48px;
    
            &:first-of-type {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
    
            &:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
    
            &:not(:first-of-type) {
                margin-left: 12px;// seule différence avec RadioButtons.scss
            }
    
            &.selected {
                z-index: 1;
                border-color: var(--ion-color-primary);
                background-color: rgba(#009FE3, 0.2);
                box-shadow: inset 0px 0px 2px 0px var(--ion-color-primary);
    
                .label {
                    color: var(--ion-color-primary);
                }
    
                .count {
                    color: white;
                    border-color: transparent;
                    background-color: var(--ion-color-primary);
                }
            }
    
            .count {
                font-weight: 500;
                padding: 6px;
                border: 1px solid #DADADA;
                border-radius: 4px;
                margin-left: 8px;
            }

            @media (max-width: $small-phone-width) {
                padding: 8px 10px;

                .label {
                    font-size: 12px;
                }
            }
        }
    }

    &.theme-standard {
        .checkbox-button {             

            &:not(:last-of-type) {
                margin-right: 16px;
            }

            ion-checkbox {
                width: 30px;
                height: 30px;
            }

            .label {
                margin-left: 10px;
            }
        }
    }
}