.fixed-back-button {
    margin-top: env(safe-area-inset-top);
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;

    ion-button {
        --background: transparent;
        --padding-start: 10px;
        --padding-end: 10px;
        --padding-top: 10px;
        --padding-bottom: 10px;
        font-size: 18px;

        ion-icon {
            color: white;
        }
    }    

    &:not(.hide-background) {
        background-color: rgba(77, 77, 77, 0.6);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        border-radius: 4px;
    }
}