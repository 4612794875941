.settings-section {
    background-color: #F1F9FF;

    h3 {
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        padding: 32px 24px 12px;
    }

    .fiq-user-login-infos {
        padding: 32px 24px;
        background-color: white;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        background-color: white;

        &:not(:last-of-type) {
            border-bottom: 1px solid #F1F9FF;
        }
    }

    .item-content {
        flex-grow: 1;
        max-width: 100%;

        & > *:not(:first-child) {
            margin-top: 10px;
        }
    }

    .item-label {
        color: #949494;
        font-size: 14px;
        line-height: 20px;
    }

    .item-value, .item-sub-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .item-value {
        line-height: 22px;
    }

    .item-sub-value {
        &:not(:first-of-type) {
            margin-top: 14px;
            padding-top: 10px;
            border-top: 1px solid #F1F9FF;
        }
    }

    .item-title {
        font-weight: 500;
        line-height: 22px;
    }

    .item-action {
        margin-left: 10px;
    }

    .updating-toggle {
        &.updating {
            pointer-events: none;
        }
    }

    .languages-selector, .subscribe-container {
        button {
            padding: 12px 24px;
            flex-grow: 1;
            border: 2px solid #DADADA;
            border-radius: 4px;
            background-color: white;
            font-size: 14px;
            font-weight: 600;
            color: inherit;
            outline: none;
            
            &:not(:first-of-type) {
                margin-left: 12px;
            }

            &:not(:last-of-type) {
                margin-right: 12px;
            }

            &.selected {
                color: var(--ion-color-primary);
                border-color: var(--ion-color-primary);
                background-color: #D6EDF8;
            }

            &[disabled] {
                pointer-events: none;
            }
        }
    }

    .languages-selector {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: white;
    }

    .subscribe-container {
        .button {
            color:white;
            background-color: #009FE3;
            border: none;
        }

        .item {
            background: none;
        }

        .button-newsletter {
            padding: 12px 24px;
            flex-grow: 1;
            border-radius: 4px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 600;
            color: inherit;
            outline: none;
            color:white;
            background-color:#009fe3;
        }
    }

    .notifications-title {
        display: flex;
        align-items: flex-end;
        
        .authorize-button button {
            padding: 0;
            height: auto;
            background-color: transparent;
            color: var(--ion-color-primary);
            padding-bottom: 14px;
        }
    }

    .social-login-container {
        padding: 24px 24px 0;
    }

    .submit-container {
        padding: 48px 24px;

        button {
            background-color: transparent;
        }
    }
}

.mobile-settings {
    .settings-section {
        .subscribe-container {
            button {
                width: 100%;
            }
        }
    }  
}