.desktop-events {
    padding: 56px 32px 16px;

    h1, .create-new {
        margin: 0 0 26px 8px;
    }

    .create-new {
        color: var(--ion-color-primary);
        font-weight: 600;
    }
}