@mixin fullScreen() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin desktop {
    @media (min-width: #{$mobile-width}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@mixin loadingAnim {
    width: 100px;
    height: 100px;
    background: url('/assets/icon/loading-static.svg') no-repeat center;
    background-size: contain;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}