.web-notifications {  

    .notification-container {
        position: absolute;
        top: 0;
        right: 0;    
        padding: 20px;    
        z-index: 10002;
        max-width: 400px;    

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
            transform: translateX(100%);
        }

        &.enter-active {
            opacity: 1;
            transform: translateX(0);          
            transition: 250ms opacity 250ms, 250ms transform 250ms;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 250ms, transform 250ms;
        }
    }

    .notification-content {
        background-color: white;
        border-radius: 4px;
        padding: 24px;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    }

    .notification-title {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 1em;
    }

    .notification-body {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 1em;
    }

    .notification-actions {
        display: flex;
        justify-content: center;
        

        button {
            font-size: 12px;
            padding: 0 8px;
            height: 32px;
        }

        button:not(:last-child) {
            margin-right: 12px;                        
        }
    }
}