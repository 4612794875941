.testimonial-form {

    .message-container {
        background-color: white;
        margin-bottom: 8px;    

        ion-textarea {
            margin: 0;
            padding: 24px;
        }
    }

    .file-upload-container {
        background-color: white;
        padding: 16px 24px;
        margin-bottom: 8px;
    }

    .public-sharing-container {
        background-color: white;
        padding: 24px;
    }

    .submit-container {
        padding: 32px 24px;
    }
}