.able-to-work-choice {

    .choice-container {
        text-align: center;

        &:not(:last-of-type) {
            border-bottom: 1px solid #F3F3F3;
            padding-bottom: 46px;
            margin-bottom: 48px;
        }

        .choice-title {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 16px;
        }

        .choice-description {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 24px;
        }
    }


}