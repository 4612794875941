.donut-progress {
    position: relative;

    svg {
        transform: rotate(-90deg);

        circle:last-of-type {
            transition: stroke-dashoffset .4s ease;            
        }
    }

    .progress-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--ion-color-primary);
        font-size: 18px;
        font-weight: 600;
    }
}