.new-testimonial-modal {
    position: relative;
    width: 648px;
    overflow: hidden;
    background-color: white;
    border-radius: 4px;        

    .testimonial-form-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 16px;
    }

    .testimonial-form {
        .message-container {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
    
            ion-textarea {
                padding: 0;
                border-radius: 4px;
                border: 1px solid #DADADA;
    
                textarea {
                    padding: 16px;
                }
            }
        }
    
        .file-upload-container {
            padding: 0;
            margin-bottom: 10px;
        }
    
        .custom-file-input {
            padding: 18px;
        }
    
        .public-sharing-container {
            padding: 0;

            .MuiFormControlLabel-label {
                font-size: 14px;
            }
        }

        .submit-container {
            padding: 40px 0 0;
        }
    }

    .testimonial-view {
        padding: 40px;

        &:first-of-type {
            position: relative;
        }

        &:not(:first-of-type) {
            position: absolute;
            top: 0;
            width: 100%;
        }

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: 125ms opacity 125ms;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 250ms;
        }
    }

    .testimonial-sent-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .testimonial-sent {
            margin-bottom: 26px;
        }
    }
}