.visible-password-input {
    position: relative;

    &:not(.showing-password) {
        input {
            font-size: 30px;
            height: 19px;
        }                
    }

    .show-password-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background: transparent;
        font-size: 32px;
        display: flex;
        outline: 0;
        justify-content: center;
        align-items: center;
    }
}