.single-news-details {

    .edit-entry {
        margin-bottom: 24px;
    }

    .title {
        font-weight: 600;
    }

    .date {
        color: #949494;        
    }

    .subtitle-separator {
        border-top: 2px solid #F8F8F9;
        width: 40px;
    }

    .subtitle-content {
        font-weight: 500;
        line-height: 22px;
    }

    .content {
        line-height: 22px;
    }
}