.mobile-map {
    
    ion-header ion-toolbar {

        ion-title {
            font-size: 18px;
        }
        
        /*&, button {
            height: 70px;
        }*/
    }

    ion-content {
        --background: #EEE;
    }

    .fixed-content {
        margin-top: 0;
        .map-container, .circles-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .scrollable-white-content {

        .scrollable-spacer {
            margin-top: 0;
        }
    }

    .circles-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }

    .location-container {        
        position: relative;
        border-bottom: 1px solid #E6E6E6;
        padding: 55px 24px 24px;

        &:before {
            position: absolute;
            top: 16px;
            left: 50%;
            margin-left: -16px;
            content: '';
            display: inline-block;
            width: 32px;
            height: 3px;
            border-radius: 4px;
            background-color: #E6E6E6;
        }

        .location-content {
            display: flex;
            align-items: center;
        }

        .location-filter-container {
            margin-bottom: 24px;
        }

        .region-status {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        .institution-name {
            font-weight: 500;
            line-height: 22px;
        }
    }

    .count-title-container {
        padding: 24px;
    }

    .declaration-type-filter-container {
        padding: 16px 24px;
    }

    .item {
        text-align: left;
        font: inherit;
        width: 100%;
        outline: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #E5E5E6;
        padding: 24px;
        background-color: #FBFBFB;

        &.unread {
            background-color: white;
            
            .declaration-content {
                font-weight: 500;
            }

            .item-icon {
                background-image: none;
                border-radius: 50%;
                background-color: var(--ion-color-primary);
            }
        }

        &.urgent {
            .declaration-line.line-hours {
                .hours-count {
                    color: var(--color-tso-urgent);
                    margin-left: 10px;
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }

        .item-content {
            margin-right: 0
        }

        .declaration-header {
            font-weight: 600;
            padding-bottom: 20px;

            .declaration-type {
                font-size: 16px;
                line-height: 22px;
            }

            .urgent {
                margin-left: 16px;
                color: var(--color-tso-urgent);
                background-color: #FCE0E7;
                padding: 8px;
                border-radius: 4px;
                font-size: 14px;                
            }

        }

        .declaration-line {
            display: flex;
            align-items: center;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            img {
                width: 20px;
                font-size: 23px;
                color: #929292;
                flex-shrink: 0;
                margin-right: 10px;
            }
        }

        .item-icon {
            background: url('/assets/icon/button-detail.svg') no-repeat center;
            background-size: cover;
            width: 16px;
            height: 16px;
        }
    }
}