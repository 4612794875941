.declaration-forewarn {
    .warned-container {

        opacity: 1;
        transition: opacity .25s;

        &.hidden {
            height: 0;
            opacity: 0;
            pointer-events: none;
        }

        .warned-content {
            display: flex;
    
            .hours-container {
                width: 50px;
                margin-right: 16px;

                input {
                    text-align: center;
                    height: 100%;
                }                
            }
    
            .period-container {
                flex-grow: 1;
            }
        }
    
        .warned-footer {
            padding: 32px;
            text-align: center;
            font-weight: 500;
        }
    }    
}