.unread-count {
    pointer-events: none;
    position: relative;
    text-align: center;

    .unread-count-content {
        position: relative;
        color: white;
        font-weight: 500;
        font-family: 'Raleway-Linings';
        font-feature-settings: "lnum" 1;
        padding: 3px;
        min-width: 18px;
    }

    .unread-count-background {        
        position: absolute;
        top: 50%;
        height: 0;
        padding-bottom: 100%;
        width: 100%;
        transform: translateY(-50%);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            background-color: var(--ion-color-danger);
            border-radius: 50%;
            border: 2px solid white;
        }
    }
}