.desktop-map {
    .desktop-map-content {        

        &:not(:first-of-type) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: 200ms opacity 200ms;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 400ms;
        }
    }
}