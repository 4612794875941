.basic-modal {

    background-color: white;
    border-radius: 4px;
    overflow: hidden;

    .basic-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: var(--ion-color-primary);
        padding: 18px 24px;
        color: white;        
        font-weight: 500;
    }

    .basic-modal-title {
        font-size: 14px;
    }

    .basic-modal-close-btn {
        display: flex;
        align-items: center;
        background: transparent;
        font-size: 28px;
        outline: none;
    }

    .basic-modal-content {
        padding: 24px;
    }
}