.map-info-button {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    background: transparent url('/assets/icon/map/info.svg') no-repeat center;
    background-size: contain;
    outline: none;
    z-index: 1;
    pointer-events: all;
}