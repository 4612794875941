@import "../styles/general/sass-variables";
@import "../styles/general/mixins";

.loading-overlay {
    z-index: 10001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.75);
    transition: opacity .25s ease;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:not(.loading) {
        pointer-events: none;
        opacity: 0;
    }

    .loading-animation {
        @include loadingAnim;
    }

    &:not(.has-label) {
        .loading-label {
            opacity: 0;
        }
    }

    .loading-label {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        top: 50%;
        margin-top: 60px;

        font-size: 32px;
        font-weight: 600;
        color: var(--ion-color-primary);
        transition: opacity .25s;
        
        padding: 0 24px;
        text-align: center;
    }

    .loading-label-content {
        display: inline-block;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0px 10px 10px white;
    }

    @include mobile {
        .loading-label {
            font-size: 18px;
        }
    }
}
