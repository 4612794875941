.fiq-button {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 0 16px;
    border-radius: 4px;
    height: 48px;
    letter-spacing: 0.1px;
    outline: none;
    transition: opacity .2s;

    &.fill-solid {
        &.color-primary {
            background-color: var(--ion-color-primary);
        }
    
        &.color-success {
            background-color: var(--ion-color-success);
        }
    
        &.color-danger {
            background-color: var(--ion-color-danger);
        }

        &.color-clear {    
            color: inherit;
            background-color: transparent;
        }

        &.color-white {
            color: inherit;
            background-color: white;
        }
    }

    &.fill-outline {
        background-color: white;

        &.color-primary {
            color: var(--ion-color-primary);
            border: 2px solid var(--ion-color-primary);
        }
    
        &.color-success {
            color: var(--ion-color-success);
            border: 2px solid var(--ion-color-success);
        }
    
        &.color-danger {
            color: var(--ion-color-danger);
            border: 2px solid var(--ion-color-danger);
        }

        &.color-white {
            color: black;            
            border: 2px solid #DADADA;
        }
    }

    &.expand-inline {
        display: inline-flex;
    }

    &.expand-block {
        display: flex;
        width: 100%;
    }

    &.mobile {
        height: 56px;
    }

    &.desktop {
        font-size: 14px;
    }

    &:not(.no-children) {
        ion-icon, img {
            margin-right: 8px;
        }
    }

    ion-icon, img {
        position: relative;        
    }

    ion-icon {
        font-size: 1.5em;
    }

    img {
        width: 18px;
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &:not([disabled]) {
        &:hover {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: white;
                opacity: 0.1;
            }
        }
    }
}