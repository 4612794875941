.quebec-map {
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: #EEE;

    &.selectable {
        position: absolute;
        width: 100%;

        & > div:not(.zoom-buttons) {
            position: absolute !important;
            width: 100% !important;
            height: 100% !important;
        }

        svg {

            g {
                &.region {
                    cursor: pointer;
                    pointer-events: auto;
                    &:hover {
                        path {
                            fill: #61676d;
                        }
                    }
                }
                &:not(.region) {
                    pointer-events: none;
                }
            }
        }

        .points {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .zoom-buttons {
            position: absolute;
            bottom: 16px;
            right: 32px;
            overflow: hidden;
            border: 1px solid #DADADA;
            border-radius: 4px;

            button {
                display: block;
                width: 28px;
                height: 28px;
                padding: 8px;
                outline: none;
                
                &:not(:last-of-type) {
                    border-bottom: 1px solid #F2F2F2;
                }

                .icon {
                    display: block;
                    height: 100%;
                    background-color: white;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &.plus .icon {
                    background-image: url('/assets/icon/map/zoom-in.svg');
                }

                &.minus .icon {
                    background-image: url('/assets/icon/map/zoom-out.svg');
                }
            }
        }
    }

    svg {
        width: 100%;
        height: 100%;

        g {
            &.region {
                &.selected {
                    path {
                        fill: #61676d;
                    }
                }
            }
        }
        rect {
            display: none;
        }
    }

    .declarations-circles-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}