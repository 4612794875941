.opened-testimonial {
    position: relative;
    width: 520px;

    &:not(.has-image) {
        .quote-container {
            padding-top: 112px;
        }
    }

    .testimonial-image {
        width: 100%;
        background-color: #F0F0F0;
    }

    .testimonial-content {
        padding: 0 40px;
        background-color: white;
    }

    .quote-container {
        padding-top: 40px;
        margin-bottom: 16px;

        img {
            width: 20px;
            height: 32px;

            &:not(:first-of-type) {
                margin-left: 8px;
            }
        }
    }

    .testimonial-message {
        margin: 0 0 32px;
    }

    .testimonial-infos {
        display: flex;
        align-items: center;
        border-top: 1px solid #E6E6E6;
        padding: 24px 8px;
        font-size: 14px;
        font-weight: 500;

        .label-container {
            width: 170px;
        }

        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        .label {
            color: #949494;            
        }
    }

    .testimonial-extra-infos {

        .extra-infos-title {
            font-weight: 600;
            padding: 24px 48px 16px;
        }

        .extra-infos-content {
            font-weight: 500;
            padding: 16px 48px;
            font-size: 14px;
            background-color: white;
        }
    }

    .testimonial-close-button-container {
        position: absolute;
        top: 24px;
        left: 0;
    }
}