.region-status {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.level-0,
    &.level-1 {
        background-image: url('/assets/icon/map/level1.png');
    }

    &.level-2 {
        background-image: url('/assets/icon/map/level2.png');
    }

    &.level-3 {
        background-image: url('/assets/icon/map/level3.png');
    }
}