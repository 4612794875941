.event-details {

    .edit-entry {
        margin-bottom: 24px;
    }

    .dates {
        color: var(--ion-color-primary);
        font-weight: 500;
        margin-bottom: 15px;
    }

    .end-date {
        &:before {
            content: '-';
            margin: 0 6px;
        }
    }

    .title {        
        font-size: 22px;
        line-height: 26px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    ion-item.location-button {
        font-weight: 500;
        line-height: 22px;
        border-top: 1px solid #EEE;
        border-bottom: 1px solid #EEE;
        --padding-top: 15px;
        --padding-bottom: 15px;
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --inner-padding-start: 0;        
        --inner-padding-end: 0;
        --background-hover: #FAFAFA;
        --background-activated: #F0F0F0;

        ion-icon {
            font-size: 16px;
            color: #949494;
            padding-bottom: 26px;
        }
    }

    .location-label {        
        margin-bottom: 4px;
    }

    .location-content {
        color: #949494;
    }

    .details-label {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        margin: 40px 0 20px;
    }

    .details-content {
        line-height: 22px;
    }
}