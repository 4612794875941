.dates-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.with-custom-ranges {
        padding: 16px 32px;
        border-bottom: 1px solid #F8F8F8;
        background-color: white;
    }

    .custom-ranges {
        display: flex;
        align-items: center;
        padding-left: 16px;

        &.active {

            .MuiOutlinedInput-root {
                font-weight: 600;
                color: var(--ion-color-primary);
                background-color: rgba(0, 159, 227, 0.2);
                box-shadow: inset 0px 0px 2px 0px var(--ion-color-primary);
            }

            fieldset {
                border-color: var(--ion-color-primary) !important;
            }
        }

        label {
            display: flex;
            align-items: center;

            &:not(:last-of-type) {
                margin-right: 16px;
            }            

            & > span {
                font-size: 14px;
                font-weight: 600;
                margin-right: 16px;
            }
        }

        .MuiOutlinedInput-root {
            font-size: 14px;
            color: #949494;
            width: 184px;
            font-weight: 500;
            font-family: Raleway;
        }
    }
}