.mobile-home {
    .tso-button {
        margin-bottom: 24px;
        --border-radius: 4px;

        .tso-btn-content {
            padding: 24px 0 15px;
            align-self: flex-start;

            h1 {
                font-Size: 24px;
                font-weight: 600;
                margin-bottom: 23px;
            }

            ion-label {
                white-space: normal;

                p {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
            }
        }

        .tso-btn-image {
            margin-left: 16px;
            padding: 15px 0;
            align-self: flex-end;

            img {
                width: 77px;
                height: 128px;
                max-width: none;
            }
        }
    }

    .substitution-button,
    .report-unsafe-care-button {

        --border-color: #f4f5f8;
        --border-radius: 4px;
        --border-width: 1.5px;
        --border-style: solid;
    
        --inner-padding-bottom: 0px;
        --inner-padding-end: 0px;
        --inner-padding-start: 0px;
        --inner-padding-top: 0px;

        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
        
        .icon {
            display: flex;
            align-items: center;
            align-self: stretch;
            flex-shrink: 0;
            padding: 26px 26px;
            background-color: var(--ion-color-primary);
            border-radius: 4px 0 0 4px;
            
            img {
                width: 44px;
                height: 44px;
            }
        }

        .content {
            padding: var(--ion-padding);
            
            .title {
                line-height: 22px;
                font-weight: 600;
            }

            .description {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.1px;
            }
        }
    }
}