.progress-bars-stats {
    padding: 0 36px;

    .progress-bars-stats-title {
        font-weight: 600;
        text-align: center;
        margin-bottom: 36px;
    }

    .progress-bars-stats-content {

        display: flex;
        flex-direction: column;
        align-items: center;

        .single-data {
            display: flex;
            width: 100%;
            max-width: 408px;
            border: 1px solid #ECECEC;
            border-radius: 4px;
            overflow: hidden;
            font-weight: 500;
            
            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            .type {                            
                width: 40px;
                padding: 6px;
                color: white;
                font-size: 20px;
                background-color: #4D4D4D;
            }

            .type-count {
                text-align: center;
            }

            .type-icon {
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            .content {
                display: flex;
                flex-grow: 1;
                position: relative;
            }

            .progress {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #4D4D4D;
                transition: width .4s;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    opacity: 0.75;
                }
            }

            .text {
                flex-grow: 1;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;                
                padding: 10px 20px;
            }

            .label {

            }

            .value {
                position: relative;
            }

            .menu {
                background: transparent;
                height: 100%;
                outline: none;
                border-left: 1px solid #ECECEC;
                font-size: 19px;
                color: #1D1D1D;
                padding: 0 10px;

                transition: background-color .2s;

                &:hover {
                    background-color: rgba(0,0,0, 0.05);
                }
            }
        }

    }
}