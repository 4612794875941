.edit-admin-email-modal {
    position: relative;
    overflow: hidden;
    width: 600px;

    .edit-admin-modal-content {        
        margin-bottom: 40px;
    }

    .edit-admin-modal-field-container {
        margin-bottom: 16px;
    }

    .edit-admin-modal-submit-container {
        display: flex;
        justify-content: space-between;
        align-items: center;        
    }

    .feedback-container {
        font-weight: 600;

        .feedback-success {
            color: var(--ion-color-success);
        }
        .feedback-error {
            color: var(--ion-color-danger);
        }
    }
}