.desktop-testimonials {
    background-color: white;

    .archived-status-selector-container {
        padding: 0 30px 30px;
        border-bottom: 1px solid #F2F2F2;
    }

    .sortable-table {

        th, td {
            &:nth-child(3) {
                width: 260px;
            }

            &:last-child {
                width: 200px;
                text-align: right;
                padding-right: 30px;
            }
        }

        .thumbnail {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            margin-right: 16px;
            border-radius: 4px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .message {
            vertical-align: middle;
        }
    }
}