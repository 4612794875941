.file-upload {

    &.drag-over {
        .file-input-container {         
            .custom-file-input {
                background-color: white;
                border-color: var(--ion-color-primary);
            }
        }
    }

    .file-input-container {
        position: relative;
        overflow: hidden;        
    
        input {
            position: absolute;
            top: 100%;
            left: 100%;
            width: 0;
            height: 0;
        }
    
        .custom-file-input {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 32px;
            background-color: #9494940D;
            border-radius: 4px;
            border: 2px dashed #DADADA;
        }
    
        .custom-file-input-content {
            display: flex;
            align-items: center;
    
            ion-icon {
                font-size: 24px;
                margin-right: 8px;
            }
    
            ion-text {
                font-size: 14px;
                font-weight: 600;
            }
        }

        .drag-cta {
            margin-top: 6px;
            font-size: 13px;
            font-weight: 600;
        }
    }
    
    .selected-file-container {
        display: flex;
        justify-content: space-between;
        align-items: center;    
        padding: 14px;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        margin-bottom: 16px;
    
        .selected-file {
            display: flex;
            align-items: center;
            font-size: 14px;
    
            ion-icon {
                font-size: 24px;
                margin-right: 8px;
            }
        }
    
        button {
            background: none;
            border: none;
            font-size: 24px;
            display: flex;
            align-items: center;
            padding: 0;
        }
    }
}