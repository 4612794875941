.admin-user-inputs {
    .input-container {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 18px;
        }

        .input-content {
            flex-grow: 1;
        }

        .input-action {
            margin-left: 26px;
        }
    }    
}