.stats-card {
    background-color: white;
    margin-bottom: 4px;
    
    &.fold-opened {
        .fold-card-button {
            transform: scaleY(-1);
        }
    }

    &:not(.compared) {
        .card-header-compared,
        .stats-card-hours .hours-difference,
        .stats-card-hours .hours-arrow {
            visibility: hidden;
        }
    }

    &.compared-loading {
        .card-header-compared,
        .stats-card-hours .hours-difference,
        .stats-card-hours .hours-arrow {
            visibility: hidden;
        }
    }

    &.no-average-hours {
        .stats-card-hours {
            visibility: hidden;
        }
    }   

    .stats-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 32px;
        border-bottom: 1px solid #F8F8F8;
        border-radius: 4px;
    }

    .card-header-compared {        
        flex-grow: 1;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0 28px;
        font-weight: 600;

        &.up {
            color: #FF3B2F;
            img {
                transform: scaleY(-1);
            }
        }

        &.down {
            color: #3BC24E;
        }

        img {
            margin-right: 6px;
        }
    }

    .card-header-action {
        a {
            display: inline-block;
            padding: 16px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .fold-card-button {
        font-size: 14px;
        align-self: stretch;
        background: transparent;
        outline: none;
        padding-left: 26px;
        margin-right: -6px;
    }

    .stats-card-hours {
        position: relative;
        margin-bottom: 60px;
        background-color: var(--ion-color-dark);
        color: white;
        padding: 16px;
        border-radius: 4px;
        font-weight: 500;

        .hours-total-label {
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
        }

        .hours-total-count {
            display: inline-block;
            vertical-align: middle;
            font-size: 40px;
            line-height: 1;
            margin-left: 26px;
        }

        .hours-arrow {
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            bottom: 0;
            height: 90%;
            margin-left: 18px;

            &.down {
                top: 0;
                bottom: auto;
                transform: scaleY(-1);
            }

            &.hidden {
                display: none;
            }
        }

        .hours-difference {
            display: inline-block;
            vertical-align: middle;
            font-size: 13px;
            letter-spacing: 0.2px;
            font-weight: 500;
            margin-left: 72px;
        }
    }

    .stats-card-content {
        position: relative;
        overflow: hidden;
    }

    .stats-card-content-inner {
        padding: 38px 32px;
    }
}