.create-account-cta {
    padding-top: 14px;
    text-align: center;
    
    .not-member-yet {
        font-size: 14px;        
    }

    .fiq-button {
        border-color: var(--ion-color-primary);
        border-width: 2px;
        color: var(--ion-color-primary);
        font-size: 16px;
        margin-top: 16px;
        height: 56px;
    }
}