.location-filter {

    &.vertical {
        display: flex;
        flex-direction: column;

        .MuiFormControl-root {
            width: 100%;
            
            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
        }
    }

    &:not(.vertical) {
        .MuiFormControl-root {
            width: 220px;
            
            &:not(:last-of-type) {
                margin-right: 8px;
            }
    
            .MuiSelect-root {
                padding-top: 14.5px;
                padding-bottom: 14.5px;
            }
        }
    }
    
}