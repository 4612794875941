.page-title-actions {
    padding: 30px;

    .page-title-actions-inner {
        justify-content: space-between;
        padding-bottom: 30px;
        border-bottom: 1px solid #F2F2F2;
    
        &, .actions-container {
            display: flex;
            align-items: center;
    
            & > * {
                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }
}