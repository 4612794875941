.login-admin-form {
    padding: 0 32px;

    .greetings-container {
        margin: 30px 0;
    }

    .admin-user-inputs-container {
        margin-bottom: 24px;
    }    

    .admin-user-forgot-password {
        text-align: right;
        font-size: 14px;        
        font-weight: 600;
        color: var(--ion-color-primary);
        margin-bottom: 32px;
    }

    .fiq-button {
        height: 56px;
        font-size: 16px;
        font-weight: 600;
        margin: 24px 0;
    }
}