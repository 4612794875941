.sortable-table {
    table {
        width: 100%;
        table-layout: fixed;
        font-size: 13px;
        
        thead {
            white-space: nowrap;
            font-weight: 600;
            color: #949494;
            position: relative;
            z-index: 1;
    
            tr {
                box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.2);
            }
    
            th {                
                padding: 22px 14px;

                &.read-status .unread-bullet {
                    visibility: hidden;
                }

                &.sort {
                    .sortable-label, button {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &.active {
                        color: var(--ion-color-primary);
                    }
                }
            }
        }

        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        }
    
        tbody {
            font-weight: 500;
            position: relative;

            tr {

                cursor: pointer;
                
                &:not(.unread) {
                    .read-status .unread-bullet {
                        visibility: hidden;
                    }
                }
    
                &.unread {
                    color: var(--ion-color-primary);
                }
    
                &.selected {
                    background-color: rgba(0,159,227,0.082);
                }
    
                &:hover {
                    box-shadow: 0px 3px 3px 0px rgba(0,0,0, 0.2);
                }
            }
    
            td {
                height: 64px;
                padding: 0 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }        
    
        th, td {
            text-align: left;
    
            &.read-status {
                padding-right: 0;
                width: 22px;
            }

            &.select {
                width: 46px;
            }
    
            ion-checkbox {
                display: block;
            }
        }
    
        .unread-bullet {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--ion-color-primary);
        }
    }

    .selected-entries-toast {
        position: fixed;
        z-index: 2;
        bottom: 0px;
        
        transform: translate(50%, 100%);
        transition: transform .2s;
        
        &.active {
            transform: translate(50%, 0%);
        }

        .selected-entries-toast-inner {
            display: flex;
            align-items: center;
            font-size: 14px;
            background-color: var(--ion-color-dark);
            color: white;
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            padding: 15px;
            margin-bottom: 10px;
        }        

        button {
            ion-icon {
                font-size: 24px;
                font-weight: 600;
            }
            margin-right: 12px;
        }

        .selected-entries-count {            
            padding: 6px 10px;
            background-color: rgba(#949494, 0.4);
            border-radius: 4px;
        }

        .selected-entries-label {
            margin-left: 12px;
            margin-right: 40px;
        }
    }
}