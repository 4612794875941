.desktop-tutorial-pages {
    &.is-tutorial {
        .pages-container {
            opacity: 0;
        }
    }

    &:not(.is-tutorial) {
        .tutorial-container {
            opacity: 0;
            pointer-events: none;
        }
    }

    .pages-container,
    .tutorial-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .3s;
    }

    .tutorial-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .tutorial-content {
            text-align: center;
        }

        .next-btn {
            margin-top: 24px;
        }
    }

    .pages-container {
        display: flex;

        .image-container {
            padding: 10% 10% 0;
            width: 50%;
        }

        .image-content {
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom center;
        }

        .pages-navigation {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 50%;
            flex-grow: 1;
            flex-shrink: 0;
            padding: 32px;

            .page-container {
                overflow: auto;
            }

            .page-content,
            .page-actions {
                width: 472px;
            }

            .navigation-content {
                position: relative;
                overflow: hidden;                
            }

            .page-progress {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -100%);
                transition: opacity .25s;
            }

            .donut-progress {
                margin: 0 auto;
            }

            .page-content {
                background-color: white;
                position: relative;
                padding: 34px;                
            }

            .page-title {
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                padding: 14px 0 32px;
            }

            .page-actions {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;

                .back-btn {
                    display: flex;
                    align-items: center;
                    background: none;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 0;
                    outline: 0;

                    ion-icon {
                        font-size: 16px;
                        margin-right: 10px;
                    }
                }

                .back-btn {
                    transition: opacity .3s;

                    &.hidden {
                        opacity: 0;
                    }
                }
            }
        }

        .component-transition {
        
            &:first-of-type {
                position: relative;
            }
    
            &:not(:first-of-type) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
    
            &.enter,
            &.exit {
                pointer-events: none;
            }
            
            &.enter {
                opacity: 0;
            }
    
            &.enter-active {
                opacity: 1; 
                transition: 250ms opacity 250ms;
            }
    
            &.exit {
                opacity: 1;
            }
    
            &.exit-active {
                opacity: 0;
                transition: opacity 500ms;
            }
        }
    }
}