.mobile-declaration-details {

    ion-content {
        --background: #F1F9FF;
    }

    .declaration-details {

        .declaration-created-at {
            text-align: center;
            padding: 16px;
            border-bottom: 1px solid #F2F2F2;
        }

        .declaration-statuses {
            padding: 16px 24px;
        }

        .declaration-base-details {
            ion-item {    
                &:not(.submitted-date) {
                    .label {
                        display: none;
                    }
                }
            }
        }        
    }
}