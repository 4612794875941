.mobile-footer {
    &:not(.no-border) {
        ion-toolbar {
            border-top: 1px solid #f6f6f6;
        }
    }

    ion-toolbar {
        padding: 16px 24px;
    }

    &:before {
        display: none;
    }    
}