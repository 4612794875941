@import "general/sass-variables";
@import "general/ion-variables";
@import "general/mixins";

@import "fonts/google/font-raleway";
@import "fonts/raleway-linings/font-raleway-500-linings";
@import "fonts/raleway-linings/font-raleway-600-linings";

@import "ionic";
@import "texts";
@import "buttons";
@import "layout";
@import "inputs";

body {
    color: #4D4D4D;
}

.ie-warning {    
    color: #009FE3;
    text-align: center;
    margin: 2em;

    h1 {
        font-size: 2em;
        font-weight: 700;
    }

    p {
        font-size: 1.2em;
    }
}

.app-content {
    height: 100%;
}

/*
@include mobile {
    body {
        background-color: #009FE3;
    }

    #root {
        width: 100%;
        height: 100%;
        background-color: white;
    }
}
*/