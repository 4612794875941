.desktop-map-declarations {

    .map-legend-container {
        position:absolute;
        padding-left: 20px;
        top: 20px;
        right: 20px;
        z-index: 1;
        transition: transform .3s ease;

        &:not(.opened) {
            transform: translateX(100%);
        }
    }

    .map-container {
        position: fixed;
        top: 0;
        width: calc(100% - var(--desktop-side-menu-width));
        height: 400px;
        pointer-events: none;

        .circles-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .declarations-container {
        position: relative;
        margin-top: 400px;
        background-color: white;
    }

    .over-map-content {
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px 16px;
    }

    .current-location {
        display: flex;
        align-items: center;
        background-color: white;
        padding: 16px;
        border-radius: 4px;            

        .region-status {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .region-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }

        .institution-name {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }

        .regionFrColon::before {
            content: '\00a0:\00a0';
            font-weight: 600;
        }
        .regionEnColon::before {
            content: ':\00a0';
            font-weight: 600;
        }
    }

    .locations-types-filter {
        padding: 30px 30px 24px;
        display: flex;
        justify-content: space-between;
    }

    .sortable-table {

        .table-container {            
            //overflow: auto;
            table {
                min-width: 1000px;
            }
        }

        th, td {
            &:nth-child(2) {
                width: 150px;
            }

            &:nth-child(3) {
                width: 170px;
            }

            &:nth-child(4) {
                width: 180px;
            }

            &:nth-child(5) {
                width: 250px;
            }

            &:last-child {
                width: 150px;
                text-align: right;
                padding-right: 30px;
            }
        }

        tbody {
            td {
                &:nth-child(2) {
                    overflow: visible;
                    text-overflow: clip;
                    white-space: normal;
                    word-wrap: normal;
                }

                &.urgent {
                    span {
                        color: var(--ion-color-danger);
                        font-size: 12px;
                        font-weight: 600;
                        padding: 8px;
                        border-radius: 4px;
                        background-color: #FCE0E7;
                    }
                }

                &.status {
                    span {
                        color: #FC4A71;
                        background-color: #FCE0E7;
                        padding: 8px;
                        border-radius: 4px;   
                    }
                }

                &.hours {
                    .urgent {
                        font-size: 18px;
                        font-weight: 600;
                        color: #FC4A71; 
                    }
                }
            }
        }
    }
}