.declarations-forewarn-stats {
    padding: 0 36px;


    &.no-data {
        .donut-container {
            visibility: hidden;
        }
    }

    .declarations-forewarn-title {
        text-align: center;
        font-weight: 600;
        margin-bottom: 36px;
    }

    .declarations-forewarn-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .donut-container {
        position: relative;
        max-width: 270px;
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
    }

    .legend-container {
        margin-left: 58px;
        flex-shrink: 0;
    }
}