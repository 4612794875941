.radio-buttons {
    display: flex;
    align-items: center;

    .radio-button {
        display: inline-flex;
        align-items: center;

        position: relative;
        outline: 0;
        border: 1px solid #DADADA;
        padding: 8px 16px;
        min-height: 48px;
        font-size: 14px;        
        background-color: transparent;

        &:first-of-type {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-of-type {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:not(:first-of-type) {
            margin-left: -1px;
        }

        &.selected {
            z-index: 1;
            border-color: var(--ion-color-primary);
            background-color: rgba(#009FE3, 0.2);
            box-shadow: inset 0px 0px 2px 0px var(--ion-color-primary);

            .label {
                color: var(--ion-color-primary);
            }

            .count {
                color: white;
                border-color: transparent;
                background-color: var(--ion-color-primary);
            }
        }

        .label {
            font-weight: 600;
        }

        .count {
            font-weight: 500;
            padding: 6px;
            border: 1px solid #DADADA;
            border-radius: 4px;
            margin-left: 8px;
        }
    }
}