.testimonial-sent {
    text-align: center;

    img {
        height: 88px;
    }

    h2 {
        margin: 16px 0 32px;
        color: #56D4DA;
    }

    p {
        margin: 0 80px;
        line-height: 22px;
    }
}