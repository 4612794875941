.militant-noticed {
    padding: 24px;
    text-align: center;

    img {
        margin: 60px 0;
        height: 100px;
    }

    h2 {
        line-height: 26px;
        margin-bottom: 26px;
    }

    p {
        line-height: 30px;
    }
}