.login-cta {
    text-align: center;
    font-weight: 600;
    
    & > :not(:last-child) {
        margin-right: 12px;
    }

    a {
        color: var(--ion-color-primary);
    }
}