.activate-notifications {

    .notifications-icon-container {
        background-color: var(--ion-color-primary);
        padding: 24px;
        border-radius: 4px;
        margin-bottom: 32px;
    }

    h2 {
        font-size: 20px;
        line-height: 26px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
        background-color: white;

        &:not(:last-of-type) {
            border-bottom: 1px solid #F1F9FF;
        }
    }

    .item-content {
        flex-grow: 1;
        max-width: 100%;

        & > *:not(:first-child) {
            margin-top: 10px;
        }
    }

    .item-label {
        color: #949494;
        font-size: 14px;
        line-height: 20px;
    }

    .item-action {
        margin-left: 10px;
    }
}