@import "../../styles/general/sass-variables";

.login-home {
    text-align: center;
    padding: 56px 24px 0;

    @media (max-height: $small-phone-height) {
        padding-top: 24px;
    }

    .ignore-third-party-logins-link {
        font-weight: 600;
        color: var(--ion-color-primary);
    }
}