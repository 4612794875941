.mobile-header {
    
    ions-buttons {
        --padding-start: 0;
    }
    ion-button {
        font-size: 20px;
        height: 56px;
    }

    &.blue, &.white.back-hidden {
        ion-toolbar {
            border-bottom: none;
        }
    }

    &.align-left {
        
    }

    &.back-hidden {
        ion-title {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &:not(.back-hidden) {
        ion-title {
            padding-left: 6px;
            padding-right: 6px;
        }
    }
}