.mobile-content {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
    
    .mobile-content-footer {
        padding: 16px 24px;
        margin-bottom: env(safe-area-inset-bottom);

        &:not(.no-border) {
            border-top: 1px solid #f6f6f6;
        }
    }
}