.able-to-work-tutorial {

    .able-to-work-icon {
        height: 68px;
        margin-bottom: 28px;
    }

    .tutorial-title {
        font-size: 32px;
        line-height: 0.81em;
        margin-bottom: 42px;
        color: var(--ion-color-primary);
        font-weight: 600;
    }

    .image-banner-container {
        margin: 0 -24px;
    }

    .image-banner {
        display: block;
        width: 100%;
    }
}