.tooltip-selector {
    position: relative;

    &:hover {
        .tooltip-selections {
            pointer-events: auto;
            opacity: 1;
        }

        .static-content {
            .label {
                color: var(--ion-color-primary);
            }
        }
    }    

    .static-content {
        display: flex;
        align-items: center;

        .label {
            margin-right: 8px;
        }

        .icon {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            background-color: var(--ion-color-primary);
            border-radius: 4px;
            padding: 6.5px;
            color: white;
            font-size: 16px;
        }
    }

    .tooltip-selections {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        transition: opacity .25s;
        padding: 22px 16px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0px 3px 3px 0px rgba(0,0,0, 0.2);
        min-width: 200px;

        .selection {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }

            ion-checkbox {
                width: 20px;
                height: 20px;
            }

            .label {
                margin-left: 10px;
                font-weight: 500;
            }
        }
    }
}