.desktop-able-to-work {
    .able-to-work-tutorial {
        margin: 0 auto;
        max-width: 580px;
        padding: 0 24px;

        .able-to-work-icon {
            height: 110px;
        }

        .image-banner-container {
            display: none;
        }
    }

    .tutorial-container {
        background: url('/assets/bg/apte-banner.svg') no-repeat bottom center;
        background-size: contain;
        justify-content: flex-start;
        padding-top: 6%;

        .tutorial-content {
            width: 100%;
            padding-bottom: 60px;
            background: linear-gradient(180deg, rgba(241,249,255,1) 0%, rgba(241,249,255,1) 70%, rgba(241,249,255,0) 100%);
        }
    }

    .pages-container {
        .image-content {
            background-image: url('/assets/bg/apte-evaluation.svg');
        }
    }
    
    .able-to-work-evaluation {
        .page-title {
            margin-bottom: 0;
        }

        .image-evaluation,
        .image-banner-container {
            display: none;
        }
    }
}