.simple-modal-controller {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000; 

    &.position-center {
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(.opened) {
            .simple-modal-content {
                opacity: 0;
            }
        }
    }

    &:not(.position-center) {
        .simple-modal-content {
            position: absolute;
            background-color: #F1F9FF;
        }
    }

    &.position-left {
        &:not(.opened) {
            .simple-modal-content {
                transform: translateX(-100%);
            }
        }

        .simple-modal-content {
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(0);
        }
    }

    &.position-right {
        &:not(.opened) {
            .simple-modal-content {
                transform: translateX(100%);
            }
        }

        .simple-modal-content {
            top: 0;
            right: 0;
            height: 100%;
            transform: translateX(0);
        }
    }
    &.position-top {
        &:not(.opened) {
            .simple-modal-content {
                transform: translateY(-100%);
            }
        }

        .simple-modal-content {
            top: 0;
            left: 0;
            width: 100%;
            transform: translateY(0);
        }
    }

    &.position-bottom {
        &:not(.opened) {
            .simple-modal-content {
                transform: translateY(100%);
            }
        }

        .simple-modal-content {
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translateY(0);
        }
    }

    .simple-modal-safe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.8;
        cursor: pointer;
        transition: opacity .25s;
    }    

    &:not(.opened) {
        pointer-events: none;

        .simple-modal-safe {
            opacity: 0 !important;
        }
    }    

    .simple-modal-content {
        position: relative;
        overflow: auto;
        transition: transform .25s, opacity .25s;        
    }
}