@import "../styles/general/mixins";

.app-loading {
    .app-loading-content {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;        
    }

    .loading-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            height: 120px;
        }
    
        .loading {
            @include loadingAnim;
        }
    }

    .error-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .offline-icon {
            height: 70px;
        }

        h1 {
            font-size: 16px;
            margin: 16px 0 10px;
        }

        p {
            font-size: 14px;
            margin: 0 0 26px;
        }

        button {
            display: flex;
            align-items: center;
            background: transparent;
            outline: none;
            font-weight: 600;
            color: var(--ion-color-primary);

            ion-icon {
                font-size: 18px;
                margin-right: 6px;
            }
            
            .label {
                font-size: 14px;
            }
        }
    }
}