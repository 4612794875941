.yes-no-buttons {
        
    button {
        width: 50%;
        height: 56px;
        justify-content: center;

        &:first-of-type {
            margin-right: 24px;
        }

        &:last-of-type {
            margin-left: 24px;
        }
        
        img {
            margin-right: 8px;
        }
    }
}