.mobile-news {

    ion-content {
        --background: #F1F9FF;
    }

    .read-status {
        transition: width .3s, background-color .3s, margin-left .3s;
        overflow: hidden;
        width: 0;
        height: 12px;
        background-color: transparent;
        border-radius: 50%;
        flex-shrink: 0;
    }

    .single-news-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-height: 1.25em;
        max-height: 3.75em;
        -webkit-box-orient: vertical;
        font-weight: 600;
    }

    .news-list {
        position: relative;
    }

    .single-news {

        &.unread {
            .read-status {
                margin-left: 12px;
                width: 12px;
                background-color: var(--ion-color-primary);
            }            
        }

        &.with-background {    
            &.unread {
                .read-status {
                    background-color: white;
                }
            }

            .single-news-text-container {
                display: flex;
                align-items: center;
            }

            .single-news-text {
                font-size: 18px;
            }

            .single-news-item {
                display: block;
                width: 100%;
                position: relative;
                color: white;
                background-image: url('/assets/bg/default-news.jpg');
                background-size: cover;
                background-position: center;
                background-color: #EEE;
                text-align: left;
                font: inherit;
                outline: none;
    
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(180deg, rgba(37,37,37,0) 0%, rgba(37,37,37,1) 100%);
                }
    
                .single-news-content {
                    position: relative;
                    padding-bottom: 75%;
                    height: 0;
                }
    
                .single-news-content-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
    
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }
    
                .single-news-title {
                    font-size: 18px;
                }
            }
        }

        &.with-thumbnail {

            &:not(:last-of-type) {
                .single-news-item {
                    border-bottom: 1px solid #F6FAFD;
                }
            }

            .single-news-item {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                background: white;
                margin: 0;
                padding: 24px;
                text-align: left;
                outline: none;
            }
    
            .single-news-title {
                font-size: 16px;
            }

            .single-news-text-container {
                flex-grow: 1;
            }

            .single-news-text {
                font-size: 16px;
                word-break: break-word;
            }

            .single-news-date {
                color: var(--ion-color-medium);
            }

            .single-news-thumbnail {
                flex-shrink: 0;
                margin-left: 24px;
                width: 96px;
                height: 56px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #FAFAFA;
                background-image: url('/assets/bg/default-news.jpg');
            }

            .read-status {
                flex-shrink: 0;
            }
        }
    }

    .list-items {        
        .single-news-title {
            font-weight: 600;
        }

        .single-news-date {
            margin-top: 1em;
            font-size: 14px;
        }
    }

    .unread-toast {

        &.hidden {
            opacity: 0;
            transform: translate(-50%, 100%);
        }
        transition: opacity .3s, transform .3s;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 20px;
        background-color: white;
        white-space: nowrap;
        padding: 13px 16px;
        color: var(--ion-color-primary);
        font-weight: 600;
        font-size: 15px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }
}

