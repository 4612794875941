@import "../../styles/general/sass-variables";

.mobile-pages {

    &:not(.tab-bar-visible) {
        ion-tab-bar {
            transform: translateY(100%);
            opacity: 0;
        }

        .tab-bar-opened {
            opacity: 0;
            transform: translateY(66px);
        }
    }

    &:not(.opened) {
        .tab-bar-opened {
            pointer-events: none;

            .safe {
                opacity: 0;
            }

            .content {
                transform: translateY(100%);
            }
        }
    }

    ion-tab-bar {
        position: absolute;
        width: 100%;
        bottom: 0;
        transition: transform .3s, opacity .3s;
        height: 66px;
    }

    ion-tab-button {
        font-weight: 600;

        .unread-count {
            position: absolute;
            margin-top: -16px;
            margin-left: 16px;
            font-size: 13px;
        }
        
        &.tab-selected {
            pointer-events: none;
        }

        &.declaration {
            --padding-start: 12px;
            --padding-end: 12px;
            padding: 0 12px;
            flex-shrink: 0;

            --background: var(--ion-color-primary);
			--color: var(--ion-color-primary-contrast);
            --color-selected: var(--ion-color-primary-contrast);
            
            &:not(:last-of-type) {
                border-right: 1px solid #7FCFF1;
            }

            img {
                width: 24px;
                flex-shrink: 0;
                margin-right: 6px;
            }

            span {
                font-size: 13px;
                text-align: left;
            }

            &:first-of-type {
                span {
                    white-space: nowrap;
                }
            }

            @media (max-width: $small-phone-width) {

                img {
                    width: 20px;
                    margin-right: 4px;
                }

                span {
                    font-size: 12px;
                }
            }
        }

        &:not(.declaration) {
            &:not(:last-of-type) {
                border-right: 1px solid rgba(230, 230, 230, 0.5);
            }

            &.toggle {

                &.opened {
                    img, ion-label {
                        display: none;
                    }
                }

                &:not(.opened) {
                    ion-icon {
                        display: none;
                    }
                }

                ion-icon {
                    font-size: 40px;
                }

                img {
                    width: 36px;
                    margin-top: 10px;
                }

                ion-label {
                    margin-top: 14px;                    
                }

                @media (max-width: $small-phone-width) {
                    ion-icon {
                        font-size: 27px;
                    }
                }
            }

            ion-icon {
                font-size: 23px;
            }

            ion-label {
                font-size: 11px;
                line-height: 1.18em;
            }
        }
    }

    .tab-bar-opened {
        position: absolute;
        bottom: 66px;
        bottom: calc(66px + env(safe-area-inset-bottom));
        left: 0;
        width: 100%;
        height: calc(100% - 66px);
        height: calc(100% - 66px - env(safe-area-inset-bottom));
        overflow: hidden;
        transition: opacity .3s, transform .3s;

        .safe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.8;
            cursor: pointer;
            transition: opacity .25s;
        }

        .content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: transform .25s;
            background-color: white;
            border-radius: 20px 20px 0 0;
            padding: 36px 0 18px;
            border-bottom: 1px solid #F3F3F3;
        }

        .title {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.33em;
            padding: 0 18px;
        }

        .links {
            margin-top: 24px;
            padding: 0 18px;
            border-top: 1px solid #F3F3F3;

            .link {
                display: flex;
                width: 100%;
                align-items: center;
                background: transparent;
                padding: 16px 0;
                outline: none;

                &.active {
                    color: var(--ion-color-primary);
                    pointer-events: none;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #F3F3F3;
                }                
                
                .icon {
                    flex-shrink: 0;
                    margin-right: 20px;

                    img {
                        width: 32px;
                    }

                    ion-icon {
                        font-size: 34px;
                        margin-left: -2px;
                    }
                }

                .label {
                    flex-grow: 1;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.42em;
                    text-align: left;
                }
            }
        }

        @media (max-height: $small-phone-height) {
            .content {
                padding: 30px 0 10px;
            }

            .title {
                font-size: 20px;
            }

            .links {
                margin-top: 12px;
    
                .link {
                    padding: 12px 0;

                    .icon {    

                        img {
                            width: 30px;
                        }

                        ion-icon {
                            font-size: 32px;
                        }                        
                    }
    
                    .label {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}