.forgot-password {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px;

    .title {
        font-size: 24px;
        font-weight: 600;
        color: var(--ion-color-primary);
        margin-bottom: 64px;
    }

    .input-container,
    .actions-container {
        width: 100%;
    }

    .input-container {
        
    }

    .feedback-container {
        font-size: 14px;
        font-weight: 600;
        margin: 16px 0 20px;
        text-align: left;
        transition: opacity .2s;
        color: var(--ion-color-primary);

        &:not(.show) {
            opacity: 0;
        }
    }

    .actions-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            &:not(:last-of-type) {
                margin-right: 16px;
            }
        }
    }
}