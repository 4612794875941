.declaration-hours {
    label:not(.MuiFormLabel-filled) + div.MuiInputBase-root input::-webkit-datetime-edit {
        color: transparent;
    }

    .date-selector-container {
        padding-top: 24px;
    }

    .date-error {
        padding-top: 14px;
        font-size: 14px;
        font-weight: 600;
        color: var(--ion-color-danger);
    }

    .hours-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;

        img {
            width: 18px;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 8px;
        }

        .label {
            flex-grow: 1;
        }

        .input-container {
            width: 72px;
            flex-shrink: 0;
            flex-grow: 0;
            
            input {
                text-align: center;
            }
        }
    }
}