.desktop-card {
    display: inline-block;
    vertical-align: top;
    width: 336px;
    margin: 8px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    &.event {
        .card-image {
            background-image: url('/assets/bg/default-event.jpg');
        }
        .card-content {
            padding: 24px;
        }
    }

    &:not(.event) {
        .card-image {
            background-image: url('/assets/bg/default-news.jpg');
        }
    }

    &.read .read-status {
        display: none;
    }

    .card-image {
        position: relative;
        height: 0;
        padding-bottom: 60%;
        background-color: #EEE;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .card-event-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        background-color: white;
        border-right: 1px solid #EEE;
        border-bottom: 1px solid #EEE;
    }

    .card-event-month {
        color: var(--ion-color-primary);
        font-size: 14px;
        text-transform: uppercase;    
    }

    .card-event-day {
        font-size: 32px;
        font-weight: 600;
        margin-top: -6px;
    }

    .card-content {
        padding: 24px 16px;
    }

    .card-title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        line-height: 22px;
        height: 66px; // 22 * 3

        font-weight: 600;
        margin-bottom: 16px;        
    }

    .card-subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        line-height: 22px;
        height: 66px; // 22 * 3

        font-size: 14px;
        font-weight: 500;
        color: #949494;
        margin: 16px 0;
    }

    .card-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        border-top: 1px solid #F8F8F8;
    }

    .read-status {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--ion-color-primary);
    }

    .card-date {
        font-size: 13px;
        font-weight: 600;
        color: #949494;
    }

    .card-location {
        display: flex;
        align-items: center;
        color: #949494;
        font-weight: 600;
        

        ion-icon {
            font-size: 18px;
            margin-right: 6px;
        }

        .label {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}