.declarations-circles {
    position: relative;
    z-index: 0;
    transition: width .4s, height .4s;

    &:not(.triangle-layout) {
        display: flex;
        align-items: center;
        min-height: 216px;
    }

    .declarations-circle {
        position: relative;
        
        display: flex;
        justify-content: center;
        align-items: center;
        transition: margin-top .4s, top .4s, left .4s, width .4s, height .4s;

        &.status-tso {            
            z-index: 1;
            &:before {
                background-color: var(--color-tso);
                opacity: 0.8;
            }
        }

        &.status-tso-urgent {
            z-index: 2;
            &:before {
                background-color: var(--color-tso-urgent);
                opacity: 0.7;
            }
        }

        &.status-substitution {
            &:before {
                background-color: var(--color-substitution);
                opacity: 0.7;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        svg {
            position: relative;
            width: 100%;
            height: 100%;

            text {
                fill: white;
                font-size: 30px;
                font-weight: 500;
            }
        }
    }
}