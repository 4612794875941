.mobile-new-declaration {

    ion-content {
        --background: linear-gradient(180deg, var(--ion-color-primary) 55%, white 60%);
    }

    .declaration-header-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px;

        display: flex;
        align-items: center;
    
        .page-title {
            flex-grow: 1;
            color: white;
            font-size: 20px;
            font-weight: 600;
        }
    }

    .white-content-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .scrollable-spacer {
            flex-shrink: 0;
            transition: height .25s ease;
        }
    }
}