.opened-event {
    position: relative;
    width: 694px;

    .close-button-container {
        position: absolute;
        top: 48px;
    }

    .event-image {
        height: 0;
        padding-bottom: 54%;
        background-image: url('/assets/bg/default-event.jpg');
        background-color: #F0F0F0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .event-details {
        background-color: white;
        padding: 32px 40px 56px;

        .date {
            font-size: 16px;
            line-height: 22px;
        }

        .title {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 16px;
        }
    }
}