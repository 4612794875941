.declaration-sent {
    text-align: center;
    
    h2 {
        color: #56D4DA;
        font-size: 20px;
    }

    img, h2, p {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    a {
        color: var(--ion-color-primary);
        text-decoration: underline;
    }
}