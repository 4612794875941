.map-legend-modal {
    background-color: white;
    border-radius: 4px;
    padding: 16px 24px 24px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;

    &.style-mobile {
        font-size: 16px;

        .legend-header {
            .title {
                font-size: 18px;
            }
        }

        .legend-content {
            .content {
                align-items: flex-start;
                flex-direction: column;
            }

            .legend-content-item {
                &:not(:last-of-type) {
                    margin-bottom: 14px;
                }
            }

            .graphics-legend {
                flex-direction: column;

                .legend-item {
                    display: flex;
                    align-items: center;

                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }
                
                .label {
                    font-size: 16px;
                    display: inline-block;
                    line-height: 1;
                }
            }
        }
    }

    .legend-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .title {
            
        }

        .btn-close {
            background: transparent;
            outline: none;
            border-radius: 50%;
            padding: 8px;
            border: 1px solid #DADADA;
            position: relative;

            img {
                width: 14px;
                height: 14px;
            }
        }
    }

    .legend-content {
        .legend-entry {
            &:not(:first-of-type) {
                padding-top: 20px;
                margin-top: 20px;
                border-top: 1px solid #F2F2F2;
            }            
        }

        .caption {
            margin-bottom: 16px;
        }

        .content {
            display: flex;
            align-items: center;
        }

        .legend-content-item {
            display: flex;
            align-items: center;

            &:not(:last-of-type) {
                padding-right: 18px;
            }            

            .label {
                margin-left: 12px;
            }
        }

        .graphics-legend {
            flex-direction: row;

            .legend-item {
                border: none;
                padding: 0;
                

                &:not(:last-of-type) {
                    padding-right: 22px;
                    margin-bottom: 0;
                }
            }
        }
    }
}