body {
    input {
        color: inherit;
    }
    
    .input-large {
    
        ion-label {
            font-size: 17.5px !important;
        }
    
        ion-input {
            --padding-top: 16px !important;
            --padding-bottom: 20px !important;
        }
    }
    
    ion-checkbox {
        --border-radius: 4px;
    }
    
    .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
        white-space: normal;
    }
    
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0,0,0,0.23);
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0,0,0,0.23);
        border-width: 1px;
    }

    @media (max-width: $small-phone-width) {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {    
                input, .MuiOutlinedInput-input {     
                    font-size: 0.85em;
                }                
            }
            label, fieldset legend span {
                font-size: 0.85em;
            }
        }
    }

    @include desktop {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {    
                input, .MuiOutlinedInput-input {
                    padding-top: 14.5px;
                    padding-bottom: 14.5px;
                }        
            }
        }
        .MuiInputLabel-outlined {
            transform: translate(14px, 18px) scale(1)
        }
    }
}