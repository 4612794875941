.date-selector {
    position: relative;

    input {
        cursor: pointer;
        appearance: none;
    }

    .calendar-icon {        
        position: absolute;
        top: 50%;
        right: 10px;
        pointer-events: none;
        font-size: 24px;
        font-weight: 500;
        transform: translateY(-50%);
        color: #4D4D4D;

        ion-icon {
            display: block;
        }
    }
}