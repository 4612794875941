.mobile-settings {

    ion-content {
        --background: #F1F9FF;
    }

    .simple-modal-content {
        padding: 24px;
    }

    .simple-modal-content,
    .edit-admin-email-modal,
    .edit-admin-password-modal {
        width: 100%;
    }
}