.desktop-activate-notifications {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .activate-notifications-container {
        border-radius: 4px;
        background-color: white;
        padding: 32px;
    }
}