.newsletter-subscribe {
    text-align: center;
    padding: 24px;
    transition: opacity .5s;
    background-color: #F1F9FF;

    h2 {
        margin: 16px 0;
    }

    .MuiFormControl-root {
        margin: 1em 0;
    }

    &.subscribed {
        pointer-events: none;
        opacity: 0.5;
    }
    
    .subscribe-container {
        .button {
            color:white;
            background-color: #009FE3;
            border: none;
        }

        .item {
            background: none;
        }

        .button-newsletter {
            padding: 12px 24px;
            flex-grow: 1;
            border-radius: 4px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 600;
            color: inherit;
            outline: none;
            color:white;
            background-color:#009fe3;
        }
    }
}
