.side-modal-close-btn {
    display: flex;
    align-items: center;        
    border: 1px solid #DADADA;
    border-radius: 0 100px 100px 0;
    padding: 16px 24px 16px 36px;
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    outline: none;        

    ion-icon {
        font-size: 24px;
    }

    .label {
        margin-left: 12px;
        letter-spacing: 0.5px;
    }
}