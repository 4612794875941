.able-to-work-steps {
    ol {
        list-style: none;
        counter-reset: item;
        padding-left: 0;
    }

    li {
        counter-increment: item;
        display: flex;
        align-items: flex-start;
        padding: 24px 0;

        &:last-of-type {
            padding-bottom: 0;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #F3F3F3;
        }

        &:before {
            content: counter(item)".";
            margin-right: 16px;
            display: inline-block;
        }

        &:before, h3 {
            font-size: 18px;
            font-weight: 600;
        }

        h3 {
            margin: 0;
        }

        p {
            margin: 10px 0 0;
        }
    }
}