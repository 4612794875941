.substitution-jobs {
    .substituted-job-other {
        position: relative;
        overflow: hidden;
        padding: 8px 0;
        height: 0;
        opacity: 0;
        transition: opacity .3s;

        &.active {
            opacity: 1;
        }
    }

    .description-caption {
        font-weight: 600;
        font-size: 20px;
        margin: 32px 0 16px;
        text-align: center;
    }
}