.scrollable-white-content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .top-notch-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: env(safe-area-inset-top);
        z-index: 3;
        background: white;
    }

    .scrollable-spacer {
        position: relative;
        height: 0;
        margin-top: env(safe-area-inset-top);
    }
    
    .scrollable-white-content-inner {
        flex-grow: 1;
        position: relative;
        z-index: 2;
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-top: -10px;
        margin-bottom: env(safe-area-inset-bottom);
    }
}