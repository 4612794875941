.desktop-login-pages {
    position: relative;
    height: 100%;

    background: url("/assets/bg/login-desktop.jpg") no-repeat center;
    background-size: cover;

    .login-pages-container {
        position: absolute;
        top: 0;
        right: 15%;
        height: 100%;
        width: 424px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .desktop-login-page-container {
        position: relative;
        max-height: 100%;
        background-color: white;
        border-radius: 4px;
        overflow: hidden;

        & > .loading-overlay {
            position: absolute;
            
            .loading-label {
                font-size: 18px;
            }
        }
    }

    .desktop-login-page {
        position: relative;
        height: 100%;

        &:not(:first-of-type) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: 250ms opacity 250ms;

            .scrollable-page-content {
                overflow: hidden;
            }
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 250ms;
        }
    }
}