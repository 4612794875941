.desktop-home {

    .home-header-fixed {
        position: fixed;
        width: calc(100% - var(--desktop-side-menu-width));
        pointer-events: none;
    }

    .home-header-static {
        visibility: hidden;
    }

    .desktop-home-content {
        position: relative;
        background-color: #F1F9FF;
    }

    .home-header {
        display: flex;
        padding: 24px 32px;

        .greetings-container {
            width: 50%;
            padding: 32px 48px;
            background-color: white;
            border-radius: 40px 0 40px 0;
        }

        .date {
            display: flex;
            align-items: center;
            font-weight: 500;

            ion-icon {
                font-size: 18px;
                margin-right: 6px;
            }

            span {
                font-size: 14px;
            }                      
        }
        
        .greetings {
            font-size: 32px;
            line-height: 40px;
            color: var(--ion-color-primary);
            margin: 32px 0 26px;
        }

        .message {
            font-size: 14px;
        }

        .graphics {
            margin-bottom: -24px;
            flex-grow: 1;
            background: url('/assets/bg/dashboard-greetings.svg') no-repeat bottom center;
        }
    }

    .home-overview-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
        background-color: white;
        border-bottom: 1px solid #F8F8F8;

        .overview-content {
            .title {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
                margin: 0 0 10px;
            }
    
            .location-container {
                margin-top: 20px;
                color: #949494;
    
                .location {
                    &:not(:last-of-type) {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        .overview-actions {
            flex-shrink: 0;
        }
    }

    .location-filter-container {
        padding: 32px;
        background-color: white;
    }

    .declarations-circles-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 54px 32px;
        
        .graphics {
            margin-right: 40px;
        }
    }

    .tsos-card-content {
        display: flex;

        .declarations-forewarn-stats-container,
        .tsos-causes-stats-container {
            width: 50%;
        }

        .declarations-forewarn-stats-container {
            border-right: 1px solid #F6F6F6;
        }
    }

    .substitutions-card-content {
        .declarations-forewarn-content {
            justify-content: center;
            margin-bottom: 64px;
        }

        .substitution-jobs-stats-container {
            display: flex;

            .substitution-substituted-jobs-stats-container,
            .substitution-current-jobs-stats-container {
                width: 50%;
            }

            .substitution-substituted-jobs-stats-container {
                border-right: 1px solid #F6F6F6;
            }
        }
    }
}