.declaration-details {
    background-color: white;
    padding-bottom: env(safe-area-inset-bottom);

    &.urgent {
        .declaration-hours {
            ion-item {    
                &.hours-regular {
                    --background: #FC4A71;
                }

                &.hours-overtime {
                    --background: #8B0020;
                }
            }
        }
    }

    &:not(.urgent) {
        
        .declaration-statuses {
            display: none;
        }

        .declaration-hours {
            ion-item {    
                &.hours-regular {
                    --background: #009FE3;
                }

                &.hours-overtime {
                    --background: #25528F;
                }
            }
        }
    }

    .declaration-created-at {
        
    }

    .declaration-statuses {
        padding: 15px 0;        
    }

    .declaration-urgent {
        padding: 8px;
        border-radius: 4px;
        font-size: 16px;
        color: var(--color-tso-urgent);
        background-color: #FCE0E7;
        font-weight: 600;
        text-align: center;
    }

    .declaration-hours {
        ion-item {
            color: white;
            --padding-top: 36px;
            --padding-bottom: 36px;
            --padding-start: 24px;
            --inner-padding-end: 24px;

            ion-img {
                width: 24px;
                margin-inline-end: 16px;
            }

            ion-text {
                &:first-of-type {
                    font-weight: 500;
                }

                &:last-of-type {
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }
    }

    .declaration-base-details {
        padding: 0 24px;

        ion-item {
            border-top: 1px solid #F2F2F2;
            --padding-start: 0;
            --inner-padding-end: 0;
            --padding-top: 12px;
            --padding-bottom: 12px;
            
            img {
                width: 24px;
                margin-right: 16px;
            }
    
            ion-label {
                font-weight: 500;
            }

            .label {
                display: inline-block;
            }
        }
    }

    .categorized-content {

        .category {
            background-color: #F1F9FF;
            padding: 26px 24px 12px;
            font-weight: 600;
        }

        .content {
            padding: 18px 24px;

            &:not(:first-of-type) {
                border-top: 1px solid #F1F9FF;
            }
        }
    }
}