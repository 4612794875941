.desktop-map-regions {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .map-container {
        position: relative;
        overflow: hidden;
        flex-grow: 1;
    }

    .regions-details {
        position: absolute;
        pointer-events: none;

        .region-container {
            position: absolute;
            z-index: 1;

            &.region-6 {
                z-index: 2;
            }
        }

        .region-content {
            position: absolute;
            transform: translate(-50%, -100%);
        }

        .status-icon {
            position: relative;

            .region-status-container {
                position: relative;
                display: block;
                background-color: white;
                padding: 8px;
                border-radius: 50%;
                box-shadow: 0 -2px 4px rgba(0,0,0,0.15);
            }

            .region-status {
                display: block;
            }

            &:before {
                content: '';
                display: block;
                width: 45%;
                height: 45%;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -85%) rotate(45deg);
                transform-origin: center;
                background-color: white;
                box-shadow: 0 -2px 4px rgba(0,0,0,0.25);
                border-radius: 20%;
            }
        }

        .region-details {
            position: absolute;
            min-width: 320px;
            left: 50%;
            bottom: 100%;
            margin-bottom: 20px;
            transform: translateX(-50%);

            transition: opacity .3s ease;
            opacity: 0;

            .region-details-content {
                position: relative;
                padding: 16px;
                background-color: white;
                border-radius: 4px;
                box-shadow: 0 -2px 2px rgba(0,0,0,0.15);
                white-space: nowrap;

                .region-name {
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--ion-color-primary);
                    margin-bottom: 20px;
                }

                .declarations-types {

                }

                .declarations-type {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 6px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #4D4D4D;

                    &.type-total {
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    &.type-tsos .circle {
                        background-color: var(--color-tso);
                    }

                    &.type-urgent-tsos .circle {
                        background-color: var(--color-tso-urgent);
                    }

                    &.type-substitutions .circle {
                        background-color: var(--color-substitution);
                    }
                }

                .declarations-type-content {
                    display: flex;
                    align-items: center;

                    .circle {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }

                    .label {

                    }
                }

                .declarations-type-count {

                }
            }



            &:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                transform-origin: center;
                background-color: white;
                box-shadow: 0 -2px 4px rgba(0,0,0,0.25);
                border-radius: 20%;
            }
            
        }
    }

    .map-legend-container {
        position:absolute;
        padding-left: 20px;
        top: 20px;
        right: 20px;
        z-index: 1;
        transition: transform .3s ease;

        &:not(.opened) {
            transform: translateX(100%);
        }
    }
}