ion-header {
    &:after {
        display: none;
    }

    ion-toolbar {
        border-bottom: 1px solid #f6f6f6;
    }
}

.ion-page {
    &.with-tabs {
        height: calc(100% - 66px);
        height: calc(100% - 66px - env(safe-area-inset-bottom));
    }
}

ion-footer {
    button {
        margin-bottom: 16px;
    }
}

pwa-toast {
    pointer-events: none;
}