.graphics-legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .legend-item {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #DADADA;
        background-color: white;
        white-space: nowrap;

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }

        .color {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 8px;
        }

        .label {
            vertical-align: middle;
            font-size: 13px;
            font-weight: 600;

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }
}