.sort-button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    margin-left: 10px;

    &.active {
        &.ascending {
            svg.up path {
                fill: var(--ion-color-primary);
            }
        }

        &.descending {
            svg.down path {
                fill: var(--ion-color-primary);
            }
        }
    }

    svg {
        display: block;
        width: 8px;
        height: 6px;

        &.up {
            transform: scaleY(-1);
            margin-bottom: 4px;
        }
    }
}