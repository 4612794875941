.desktop-settings {

    h2 {
        font-size: 24px;
        line-height: 32px;
        margin: 32px 0 0 32px;
    }

    &.can-social-login {
        .scrollable-content {
            padding-right: 430px;
        }
    }

    .scrollable-content {
        display: inline-block;
        width: 100%;        
    }

    .social-login-container {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        margin-left: -430px;
        width: 430px;
        height: 100%;
        pointer-events: none;
        background-color: white;
        padding: 56px;

        .social-login-content {
            position: relative;
            text-align: center;        
        }        

        button:not([disabled]) {
            pointer-events: all;
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;

            &.background-top-left {
                background-position: top left;
                background-image: url('/assets/bg/social-login-top-left.svg');
            }

            &.background-bottom-right {
                background-position: bottom right;
                background-image: url('/assets/bg/social-login-bottom-right.svg');
            }
        }
    }

    .settings-section {
        padding: 32px 32px 48px;

        .user-login-infos {
            display: flex;
            flex-wrap: wrap;

            .item {
                flex-grow: 1;
            }

            .item:nth-child(1),
            .item:nth-child(2),
            .item:nth-child(3) {

                &:not(:first-child) {
                    border-left: 1px solid #F1F9FF;
                }
                width: 33.3%;
            }
        }

        .notifications-title {
            
            .authorize-button button {
                padding-bottom: 16px;
            }
        }

        .languages-selector {
            justify-content: flex-start;
            
            button {
                flex-grow: 0;
            }
        }

        .submit-container {
            text-align: center;

            button {
                display: inline-block;
                width: auto;
            }
        }
    }
}