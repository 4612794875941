.declaration-tutorial {
    text-align: center;

    h1 {
        color: var(--ion-color-primary);
        margin-bottom: 42px;
    }

    p {
        max-width: 560px;
        margin: 0 auto;
    }
}