.mobile-activate-notifications {
    .activate-notifications-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .authorize-notifications-buttons {
            display: flex;
            margin: 0 -12px;

            button {
                flex-grow: 1;
                margin: 0 12px;
            }
        }
    }
}