@import "../../styles/general/sass-variables";

.dashboard-side-menu {
    padding: 8px;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: #004681;

    .side-menu-header {
        align-self: center;
        margin: 30px 0 66px;

        img {
            width: 44px;
        }
    }

    .side-menu-content {
        flex-grow: 1;
    }

    .side-menu-footer {
        margin-bottom: 10px;
    }

    .side-menu-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        border-radius: 4px;
        transition: background-color .2s, color .2s;
        color: #B2C7D9;
        font-weight: 600;

        &:not(:last-of-type) {
            margin-bottom: 6px;
        }
        
        ion-icon {
            font-size: 22px;
            margin-right: 12px;
            flex-shrink: 0;
        }

        .icon {
            position: relative;
            width: 20px;
            margin-right: 14px;
            flex-shrink: 0;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 70, 129, 0.3);
                transition: background-color .2s;
            }
        }

        .label {
            flex-grow: 1;
        }

        .unread-count {
            flex-shrink: 0;
            margin-left: 8px;
        }

        span {
            font-size: 14px;
        }

        &.active {
            pointer-events: none;
        }

        &.report {
            background-color: white;
            color: var(--ion-color-primary);
            justify-content: center;

            .label {
                flex-grow: 0;
            }

            &:hover {
                color: black;
            }

            &.active {
                color: black;
            }
        }

        &:not(.report) {
            &:hover {
                color: white;
                background-color: rgba(255, 255, 255, 0.1);

                .icon {
                    &:after {
                        background-color: rgba(0, 70, 129, 0.1);
                    }
                }
            }
    
            &.active {
                color: white;
                background-color: rgba(255, 255, 255, 0.3);

                .icon {
                    &:after {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .cms-section {
        margin: 16px 0;
    }

    @media (max-height: $small-desktop-height) {
        .side-menu-header {
            margin: 10px 0 20px;
        }

        .side-menu-link {
            padding-top: 8px;
            padding-bottom: 8px;

            span {
                font-size: 14px;
            }
        }
    }
}