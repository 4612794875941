@import "../../styles/general/sass-variables";

.able-to-work-evaluation {

    .page-title {
        margin-bottom: 2em;
        text-align: center;
    }

    .image-evaluation {
        margin: 0 auto;
        width: 100px;
        height: 130px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    .items-container {
        border: 1px solid #F8F8F8;        
        border-radius: 4px;
        overflow: hidden;
    }

    .item-container {
        display: flex;

        &:not(:last-of-type) {
            border-bottom: 1px solid #F8F8F8;
        }

        &.item-task img {
            width: 56px;
            height: 56px;
        }
        &.item-state img {
            width: 55px;
            height: 54px;
        }
        &.item-environment img {
            width: 56px;
            height: 45px;
        }
    }

    .item-icon {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        justify-content: center;
        align-items: center;
        width: 104px;
        padding: 24px; 
        background-color: var(--ion-color-primary);
    }

    .item-content {
        padding: 24px;
        background-color: white;
    }

    .item-title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
    }

    .image-banner-container {
        margin: 0 -24px;
    }

    .image-banner {
        display: block;
        width: 100%;
    }
    
    @media (max-width: $small-phone-width) {
    
        .item-container {    
            &.item-task img {
                width: 56px;
                height: 56px;
            }
            &.item-state img {
                width: 55px;
                height: 54px;
            }
            &.item-environment img {
                width: 56px;
                height: 45px;
            }
        }
    
        .item-icon {
            width: 80px;
            padding: 18px; 
        }
    
        .item-content {
            padding: 18px;
        }
    
        .item-title {
            font-size: 16px;
            margin-bottom: 6px;
        }

        .item-description {
            font-size: 14px;
        }
    }

    @media (max-height: $small-phone-height) {        
        .image-evaluation {
            width: 80px;
            height: 104px;
        }
    }
}