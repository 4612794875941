.mobile-footer-buttons {

    .btns-container {
        position: relative;
    }    

    .btns-content {
        display: flex;

        &:first-of-type {
            position: relative;
        }

        &:not(:first-of-type) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        &.enter,
        &.exit {
            pointer-events: none;
        }
        
        &.enter {
            opacity: 0;
        }

        &.enter-active {
            opacity: 1; 
            transition: 250ms opacity 250ms;
        }

        &.exit {
            opacity: 1;
        }

        &.exit-active {
            opacity: 0;
            transition: opacity 500ms;
        }
    }

    button {

        &.large-btn {
            width: 100%;

            &.back {
                color: black;
                --border-color: #ECECED;
            }
        }

        &.prev-btn {
            margin-right: 10px;
            color: black;
            --border-color: #ECECED;
        }

        &.next-btn {
            margin-left: 10px;
        }

        &.prev-btn, &.next-btn {
            width: 50%;
        }
    }
}