.excel-export-modal {
    .filter-name {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #949494;
    }

    .date-filter-container {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;

        .filter-name {
            margin-bottom: 16px;
        }

        .dates-selector {
            flex-direction: column;
            padding: 0;

            .determined-ranges {
                width: 100%;
                margin-bottom: 24px;
            }

            .radio-buttons {
                width: 100%;

                .radio-button {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }

    .page-filter {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid #E6E6E6;
    }

    .submit-container {
        text-align: right;
    }
}