.opened-declaration {
    position: relative;
    width: 616px;

    .declaration-content {
        background-color: white;

        .declaration-type {
            margin: 0 40px;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .close-button-container {
            padding: 24px 0 72px;
        }

        .declaration-created-at {
            font-size: 14px;
            margin-bottom: 16px;
        }
        
        .declaration-created-at,
        .declaration-statuses,
        .declaration-hours,
        .declaration-base-details {
            margin-left: 40px;
            margin-right: 40px;
        }

        .declaration-hours {

            ion-item {
                border-radius: 4px;
                --padding-top: 8px;
                --padding-bottom: 8px;
                --padding-start: 16px;
                --inner-padding-end: 16px;

                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }

                ion-img {
                    width: 14px;
                    margin-inline-end: 8px;
                }

                ion-text {
                    &:first-of-type {
                        font-weight: 500;
                    }
    
                    &:last-of-type {
                        font-size: 32px;
                        font-weight: 500;
                    }
                }
            }
        }

        .declaration-base-details {
            padding: 0 16px;

            ion-item {
                --padding-top: 8px;
                --padding-bottom: 8px;

                &.registration-number {
                    .extra {
                        display: none;
                    }
                }
    
                img {
                    width: 16px;
                }
    
                ion-label {
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                }
    
                .label {
                    color: #949494;
                    width: 160px;
                    flex-shrink: 0;
                }
            }
        }    
        
        .categorized-content {
            .category, .content {
                padding-left: 56px;
                padding-right: 56px;
            }
        }
    }
}