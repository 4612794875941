.mobile-events {

    ion-content {
        --background: #F1F9FF;
    }

    .events-list {
        ion-card {
            .image-container {
                height: 0;
                background-image: url('/assets/bg/default-event.jpg');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: #EEE;
                padding-bottom: 60%;
            }
    
            .date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                background-color: white;
                border-right: 1px solid #EEE;
                border-bottom: 1px solid #EEE;
    
                .month {
                    color: var(--ion-color-primary);
                    text-transform: uppercase;
                }
    
                .day {
                    color: black;
                    font-size: 32px;
                    font-weight: bold;
                    margin-top: -4px;
                }
            }

            ion-card-title span {
                display: block;
                padding: 10px 8px 0;
            }

            .card-content-separator {
                padding: 12px 24px;
            }

            .card-content-inner {
                display: flex;
                align-items: center;
                padding: 0 4px;
                color: #949494;
                font-weight: 600;

                ion-icon {
                    margin-right: 6px;
                    font-size: 20px;
                }

                ion-label {
                    font-size: 16px;
                }
            }
        }
    }
}