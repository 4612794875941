.count-title {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;

    .count-title-count {
        display: inline-flex;
        justify-content: center;
        align-items: center;        
        min-width: 38px;
        min-height: 38px;
        padding: 6px;
        background-color: var(--ion-color-primary);
        color: white;        
        border-radius: 4px;
        margin-right: 16px;
    }

    .count-title-title {
        font-size: 20px;        
    }
}