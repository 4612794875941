.desktop-post-editor {    

    h1 {
        padding: 56px 32px 16px;
    }

    h2 {
        margin: 0 0 24px;
        border-bottom: 1px solid #ECECEC;
        padding-bottom: 24px;
    }

    .form-container {
        background-color: white;
        padding: 32px;
    }

    form {
        
    }

    .form-inner {
        display: flex;
    }

    .texts-container {
        flex-grow: 1;

        .input-date-container {
            display: inline-block;
            width: 240px;
    
            &:not(:last-child) {
                margin-right: 32px;
            }        
        }

        .input-textarea-container {
            textarea {
                padding: 0;
                line-height: 1.5;
                min-height: 188px;
            }
        }
    
        .input-text-container,
        .input-textarea-container,
        .input-date-container {
            padding: 16px 0;
        }
    
        .input-text-container,
        .input-textarea-container {
            display: block;
        }
    }

    .images-container {
        margin-left: 32px;
        padding-left: 32px;
        border-left: 1px solid #ECECEC;
        width: 420px;

        .image-container {
            &:not(:last-of-type) {
                border-bottom: 1px solid #ECECEC;
                padding-bottom: 32px;
                margin-bottom: 32px;
            }
        }

        .image-label {
            padding-bottom: 24px;
        }

        .entry-image {
            height: 0;
            padding-bottom: 54%;
            margin-bottom: 32px;
            background-color: #F0F0F0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .submit-container {
        padding: 24px 0;
        
        a, button {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    &.type-news {
        .entry-image {
            background-image: url('/assets/bg/default-news.jpg');
        }
    }

    &.type-events {
        .entry-image {
            background-image: url('/assets/bg/default-event.jpg');
        }
    }
}